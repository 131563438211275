import React from "react"
import { Content, Layout, Heading1, Heading2, SEO } from "../components"
import styled from "styled-components"
import { Link } from "gatsby"

const Tip = styled.div`
  border-left: 0.5rem solid ${(props) => props.theme.colors.secondaryAlt};
  color: ${(props) => props.theme.colors.secondaryAlt};
  border-radius: 0 10px 10px 0;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  max-width: 90%;

  a {
    color: ${(props) => props.theme.colors.secondary};
  }
`

const TipTitle = styled.h3`
  color: ${(props) => props.theme.colors.secondaryAlt};
  margin-top: 0px;
`

const Help = () => {
  return (
    <Layout>
      <SEO title="Help" />
      <Content>
        <Heading1>Help</Heading1>
        <Tip>
          <TipTitle>Found a bug or have an feature request?</TipTitle>
          <div>
            <a href="https://github.com/kepi/chromeEyeDropper/issues">Create new issue</a> at
            GitHub. Both bug reports and feature requests are welcomed.
          </div>
        </Tip>

        <Heading2>How to use</Heading2>
        <ul>
          <li>
            <a href="http://github.com/kepi/chromeEyeDropper/wiki/Pick-from-webpage">
              Pick from webpage
            </a>
          </li>
          <li>
            <a href="http://github.com/kepi/chromeEyeDropper/wiki/Color-Picker">Color Picker</a>
          </li>
        </ul>
        <Heading2>FAQ</Heading2>
        <ul>
          <li>
            <Link to="/help/file-urls">
              How to pick color from local <code>file://</code> URLs?
            </Link>
          </li>
          <li>
            <a href="http://github.com/kepi/chromeEyeDropper/wiki/Why-I-can%27t-pick-color-from-chrome-extension-gallery%3F">
              Why I can&#8217;t pick color from chrome extension gallery?
            </a>
          </li>
          <li>
            <a href="http://github.com/kepi/chromeEyeDropper/wiki/Is-it-possible-to-disable-color-picker%3F">
              Is it possible to disable color picker?
            </a>
          </li>
          <li>
            <a href="http://github.com/kepi/chromeEyeDropper/wiki/I-found-bug%2C-what-should-I-do%3F">
              I found bug, what should I do?
            </a>
          </li>
          <li>
            <a href="http://github.com/kepi/chromeEyeDropper/wiki/I-think-it-should-also-do-this-thing...">
              I think it should also do this thing&#8230;
            </a>
          </li>
          <li>
            <a href="http://github.com/kepi/chromeEyeDropper/wiki/I-like-this-extension%2C-can-I-help%3F">
              I like this extension, can I help?
            </a>
          </li>
        </ul>
      </Content>
    </Layout>
  )
}

export default Help
